import {
    createBaseThemeOptions,
    createUnifiedTheme,
    palettes,
  } from '@backstage/theme';
  
  export const customThemeDark = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: palettes.dark,
    }),
    components: {
      BackstageHeader: {
        styleOverrides: {
          header: () => ({
            backgroundImage: 'linear-gradient(90deg, #005B4B, #005B9B)'
          }),
        },
      },
    },
  });