import {
  HomePageToolkit,
  // HomePageCompanyLogo,
  HomePageStarredEntities,
  // TemplateBackstageLogo,
  TemplateBackstageLogoIcon,
  HomePageRecentlyVisited,
  HomePageTopVisited
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React from 'react';

// const useLogoStyles = makeStyles(theme => ({
//   container: {
//     margin: theme.spacing(5, 0),
//   },
//   svg: {
//     width: 'auto',
//     height: 100,
//   },
//   path: {
//     fill: '#7df3e1',
//   },
// }));

export const HomePage = () => {
  // const { svg, path, container } = useLogoStyles();

  return (
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            {/* <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            /> */}
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited  />
              </Grid>

              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>

              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://logs.corcode.com',
                      label: 'Logs',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://metrics.corcode.com',
                      label: 'Metrics',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://docs.corcode.com',
                      label: 'Docs',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://portainer.corcode.com',
                      label: 'Portainer',
                      icon: <TemplateBackstageLogoIcon />,
                    }
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <InfoCard title="Composable Section">
                  {}
                  <div style={{ height: 370 }} />
                </InfoCard>
              </Grid> */}
            </Grid>
          </Grid>
        </Content>
      </Page>
  );
};